<template>
	<w-dialog v-model="showDialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="title" @close="close()">
		<v-layout>
			<v-flex>{{ message }}</v-flex>
		</v-layout>
		<template v-slot:actions>
			<v-layout row>
				<v-spacer />
				<w-button flat @click="doDelete()">{{ $t('actions.yes') }}</w-button>
				<w-button flat @click="close()">{{ $t('actions.no') }}</w-button>
			</v-layout>
		</template>
	</w-dialog>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'ItemDeletion',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: [Object, Array],
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			showDialog: true
		}
	},
	computed: {
		model: {
			get: function () {
				return Array.isArray(this.value) ? this.value : [this.value] || []
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		node: function () {
			return this.model.length == 1 ? this.model[0] : null
		},
		message: function () {
			let result = null
			if (this.node && this.node.is_folder) {
				result = this.$t('documents.delete_folder_message', { itemName: this.node.name })
			} else if (this.node && !this.node.is_folder) {
				result = this.$t('documents.delete_document_message', { itemName: this.node.name })
			} else if (!this.node) {
				result = this.$tc('documents.delete_elements_message', this.model.length)
			}
			return result
		},
		title: function () {
			let result = null
			if (this.model.every(node => node.is_folder)) {
				result = this.$tc('documents.delete_folder', this.model.length)
			} else if (this.model.every(node => !node.is_folder)) {
				result = this.$tc('documents.delete_documents', this.model.length)
			} else if (this.model.length > 1) {
				result = this.$t('documents.delete_elements')
			}
			return result
		},
		successMessage: function () {
			let result = null
			if (this.model.every(node => node.is_folder)) {
				result = this.$tc('ecm.folder_deleted', this.model.length)
			} else if (this.model.every(node => !node.is_folder)) {
				result = this.$tc('ecm.documents_deleted', this.model.length)
			} else if (this.model.length > 1) {
				result = this.$tc('ecm.elements_deleted', this.model.length)
			}
			return result
		}
	},
	destroyed: function () {
		this.close()
	},
	methods: {
		doDelete: function () {
			const onSuccessMessage = this.successMessage
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			const nodesToDelete = [...this.model]
			this.eventBus.emit(this.events.DELETION_STARTED)
			this.model = []
			this.close()
			Promise.all(
				nodesToDelete.map(node => {
					let action
					if (node.is_folder) {
						action = this.service.deleteFolder(this.vendorId, node)
					} else {
						action = this.service.deleteDocument(this.vendorId, node).then(() => {
							return this.eventBus.emit(this.events.DOCUMENT_DELETED, node)
						})
					}
					return action
				})
			)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, onSuccessMessage)
				})
				.finally(() => {
					this.eventBus.emit(this.events.DELETION_ENDED)
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		},
		close: function () {
			this.showDialog = false
			this.$emit('close')
		}
	}
}
</script>
